import React, { ReactElement, useEffect } from 'react';
import { Layout } from '../components/Layout/Layout';
import { Intro } from '../modules/Intro/Intro';


const ProDealPage = (): ReactElement => (
    useEffect(()=>{
        setTimeout(function(){
            window.location.replace('https://checkout.sketchwow.com?add-to-cart=17')
        }, 100)
        

        }, []),

  <Layout>
        <Intro
      title={
        <> </>
      }
      subTitle={<>Thank you for expressing interest in SketchWow, You will be redirected to checkout shortly.</>}
      
    />
  </Layout>
);

export default ProDealPage;
